

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


body{
    font-family: Heebo;
    background-size: cover;
    background-color: black;
    background-repeat: no-repeat;
    
}

a{
    text-decoration: none;
}
.card{
    margin: 0 auto;
    display: block;
    background-color: black;
    width: 80%;
    color: white;
}
.profile-info, .cta, .about, .interests, .social-media{
    padding: 0 35px;
}

.profile-img {
    display: flex;
    justify-content: center; /* Centers the image horizontally */
    align-items: center; /* Centers the image vertically if needed */
}

.profile-img > img {
    width: 13rem;
}

.profile-info{
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}
.profile-name{
    font-size: 30px;
    font-weight: 700;
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.8);
}
.profile-occupation{
    font-size: 24px;
    font-weight: 400;
    color: #e1ba66;
}
.profile-website{
    font-size: 12px;
}

.cta{
    margin-top: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-link {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: white;
    align-items: center;
    width: 150px;
}

.icon-container {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: black; /* Dark background for the circle */
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.7px solid #e1ba66 ; /* Light purple border */
    position: relative;
}

.icon-container i {
    color: #e1ba66 ;
    font-size: 38px;
}


.custom-link h3 {
    margin-top: 5px;
    font-size: 1.1rem;
    color: white;
}

#phone-icon {
    font-size: 1.8rem;
}

.cta-btn{
    width: 100px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:8px;
    border-radius: 25px;
    border: 1px solid black;
    font-weight: 500;
    font-size: 14px;
}
.cta-btn-email{
    color: #374151;
    border-color: #D1D5DB;
}

.cta-btn-course{
    width: 25rem;
    margin: 0 20%;
    margin-top: 15px;
    color: white;
    font-size: large;
    background-color: #e1ba66;
    border: 3px solid white;
    cursor: pointer;
}

.cta-btn-course i{
    color: white;
}

.about, .interests{
    margin-top: 20px;
}
.section-title{
    text-align: center;
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 8px;
}
.section-content{
    font-size: 12px;
    color: #4A4E74;
    line-height: 1.6;
}
.social-media{
    margin-top: 30px;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    gap: 24px;
    background-color: black;
    border-radius: 0 0 10px 10px;
}

.interests {
    justify-items: center;
    font-size: 1.1rem;
}

/* Styling for the contact button */
#savecontact-button {
    display: inline-flex; /* Use flexbox for alignment */
    align-items: center; /* Center items vertically */
    padding: 12px 24px;
    background-color: #4CAF50; /* Green background */
    color: white; /* White text */
    text-decoration: none; /* Remove underline */
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-family: Arial, sans-serif;
    transition: background-color 0.3s, transform 0.2s;
}

/* Hover effects */
#savecontact-button:hover {
    background-color: #45a049;
    transform: translateY(-2px);
}

/* SVG Icon Styling */
#savecontact-button .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px; /* Space between icon and text */
    fill: white; /* Icon color */
    transition: fill 0.3s;
}

#savecontact-button:hover .icon {
    fill: #e0e0e0;
}

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    text-align: center;
  }
  
  .close-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e1ba66;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 16px;
    border-radius: 4px;
  }
  
  .close-btn:hover {
    background-color: #d1a456;
  }
  


/* Responsive adjustments for mobile screens */
@media (max-width: 768px) {
    .body {
        background-image: none;
        background-color: black;
        overflow-y: hidden;
    }
    .App {
        background-color: black;
        padding: 0px 0px; /* Adjust padding on smaller screens */
    }

    .card {
        width: 100%; /* Reduce the card width to fit smaller screens */
        border-radius: 0px;
    }

    .profile-img > img {
        width: 15rem;
    }

    .profile-info {
        gap: 0px; /* Reduce the gap between profile info sections */
        margin-top: -10px;
        margin-bottom: 5px;
    }

    .profile-name {
        font-size: 22px; /* Adjust font size for better readability on mobile */
    }

    .profile-occupation {
        font-size: 12px; /* Slightly reduce font size */
    }

    .custom-link {
        width: 100px;
    }

    .icon-container {
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: 1.7px solid #e1ba66 ; 
        position: relative;
    }
    
    .icon-container i {
        color: #e1ba66 ;
        font-size: 28px;
    }

    #phone-icon {
        font-size: 1.3rem;
    }

    .cta {
        margin-top: 0px;
    }

    .cta-btn {
        width: 100%; 
        margin: 0px 0%;
        margin-top: 15px;
    }
    

    .about, .interests {
        margin-top: 10px;
    }

    .interests h4 {
        font-size: 1.3rem;
    }

    .section-title {
        font-size: 25px;
    }

    .section-content {
        font-size: 11px;
        line-height: 1.5;
    }

    .social-media {
        flex-direction: column; /* Stack social media icons vertically on mobile */
        gap: 16px;
        padding: 15px 0;
    }

    .cta-btn-course{
        width: 20rem;
        margin: 0 10%;
        margin-top: 15px;
        color: white;
        font-size: large;
        background-color: #e1ba66;
        border: 3px solid white;
        cursor: pointer;
    }    

}

@media (max-width: 480px) {
    html, body {
        overflow-y: auto;
        height: 100%;
    }    

    .profile-img > img {
        width: 60dvw;
    }

    .cta-btn-course{
        width: 90%;
        margin: 0 0;
        margin-top: 15px;
        color: white;
        font-size: large;
        background-color: #e1ba66;
        border: 3px solid white;
        cursor: pointer;
    }
    .profile-name {
        font-size: 24px;
        text-align: center;
    }

    .profile-occupation {
        font-size: 18px;
    }

    .cta {
        margin-top: 10px;
    }

    .section-title {
        font-size: 24px;
    }

    .section-content {
        font-size: 14px;
    }
}
